import React, { useState, useEffect } from 'react';
import './Card.css';
import { supabase } from '../supabaseClient';
import '../pages/ActorDetail.css';

const Card = ({ actor, onClick }) => {  // onClick prop 추가
  const [imageSrc, setImageSrc] = useState('');

  const decodeHTML = (str) => {
    if (!str) return '';
    return str.replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&amp;/g, '&');
  };

  useEffect(() => {
    const fetchCoverImage = async () => {
      try {
        const { data: imagesData, error: imagesError } = await supabase
          .from('actor_images')
          .select('url')
          .eq('actor_id', actor.id)
          .eq('type', 'cover')
          .maybeSingle();

        if (imagesError) throw imagesError;

        setImageSrc(imagesData?.url || '/img/failcoverimg.png');
      } catch (error) {
        setImageSrc('/img/failcoverimg.png');
      }
    };

    fetchCoverImage();
  }, [actor.id]);

  const calculateAge = (birthdate) => {
    if (!birthdate) return '정보 없음';
    const birthYear = parseInt(birthdate.substring(0, 4), 10);
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear + 1;
  };

  const agePercentage = (age) => {
    const maxAge = 100;
    return (age / maxAge) * 100;
  };

  const heightPercentage = (height) => {
    const maxheight = 220;
    return (height / maxheight) * 100;
  };

  const weightPercentage = (weight) => {
    const maxweight = 140;
    return (weight / maxweight) * 100;
  };

  const genderClass = actor.gender === 'male' ? 'card-actor-male' : 'card-actor-female';

  return (
    <div className="actor-card" onClick={onClick}>  {/* 외부에서 onClick 핸들러를 받음 */}
      <img 
        src={imageSrc} 
        alt={actor.name} 
        className="actor-image" 
        onError={() => setImageSrc('/img/failcoverimg.png')} 
      />
      <div className="card-actor-info">
        <div className="card-name-gender-group">
          <div className="card-actor-name">{actor.name}</div>
          <div className="gender-fill">
            <div className={genderClass}>{actor.gender === 'male' ? '남자' : '여자'}</div>
          </div>
        </div>
        <div className="card-brith-group">
          <div className="fill-text">{actor.birthdate === null ? '-' : `${calculateAge(actor.birthdate)}세`}</div>
          <div className="card-gauge">
            <div className="age-bar">
              <div 
                className={actor.gender === 'male' ? 'age-bar-fill-male' : 'age-bar-fill-female'}
                style={{ width: `${agePercentage(calculateAge(actor.birthdate))}%` }}></div>
            </div>
          </div>
        </div>
        <div className="card-height-group">
          <div className="fill-text">{actor.height} cm</div>
          <div className="card-gauge">
            <div className="age-bar">
              <div 
                className={actor.gender === 'male' ? 'age-bar-fill-male' : 'age-bar-fill-female'}
                style={{ width: `${heightPercentage(actor.height)}%` }}></div>
            </div>
          </div>
        </div>
        <div className="card-weight-group">
          <div className="fill-text">{actor.weight} kg</div>
          <div className="card-gauge">
            <div className="age-bar">
              <div 
                className={actor.gender === 'male' ? 'age-bar-fill-male' : 'age-bar-fill-female'}
                style={{ width: `${weightPercentage(actor.weight)}%` }}></div>
            </div>
          </div>
        </div>
        <div className="card-filmography">{actor.filmography ? decodeHTML(actor.filmography) : '-'}</div>
      </div>
    </div>
  );
};

export default Card;
