import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  
  return (
    <header className="header">
        <div className='header-left'>
        <Link to="/"> {/* 메인 화면으로 가는 Link */}
        <img src="/img/ToidLogow.png" alt="TOID AI Logo" className="logo" />
      </Link>
        </div>
      <div className="header-right">
      <Link to="/toidai">
     <button className="header-button-ai">AI 매칭추천</button>
      </Link>
      <Link to="/actorList">
     <button className="header-button">배우&모델 찾기</button>
      </Link>
      <Link to="/hiring">
     <button className="header-button">작품구인</button>
      </Link>
      <Link to="/notice">
     <button className="header-button">공지사항</button>
      </Link>
      <Link to="/register">
      <button className="register-button">프로필 등록하기</button>
      </Link>
      <Link to="/menu" className="menu-link">
      <button className="header-button-menu">
        <img className="header-button-img"src="/img/menu.png" alt="menu"/>
      </button>
      </Link>
      </div>
    
    </header>
  );
}

export default Header;
