// src/utils/api.js
import { supabase } from '../supabaseClient';

// Supabase에서 배우와 모델 데이터 가져오기
export const fetchActorsAndModels = async () => {
  const { data, error } = await supabase
    .from('actors')
    .select('*') // 모든 컬럼 선택
    .order('point', { ascending: false });

  if (error) {
    // console.error('Error fetching actors:', error.message);
    return [];
  }

  // console.log('Fetched actors:', data); // 데이터가 올바르게 가져와지는지 확인
  return data;
};


export const fetchActorImages = async (actorIds) => {
  try {
    const { data, error } = await supabase
      .from('actor_images')
      .select('*')
      .in('actor_id', actorIds)
      .eq('type', 'cover'); // cover 타입만 가져오기

    if (error) {
      console.error('Error fetching actor images from Supabase:', error.message);
      return [];
    }

    console.log('Supabase에서 가져온 배우 이미지 데이터:', data);
    return data;
  } catch (error) {
    console.error('Error fetching actor images:', error.message);
    return [];
  }
};

export const fetchHiringData = async () => {
  const { data, error } = await supabase
    .from('hiring')
    .select('*') // 모든 컬럼 선택
    .order('created_at', { ascending: false }); // 최신 순으로 정렬

  if (error) {
    console.error('Error fetching hiring data:', error.message);
    return [];
  }

  return data;
};



// OpenAI ChatGPT API를 사용해 추천 배우 가져오기
export const fetchActorsFromAI = async (synopsis, characterInfo) => {
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  try {
    // Supabase에서 배우 정보를 가져오기
    const { data: actors, error: actorError } = await supabase
      .from('actors')
      .select('id, name, birthdate, gender, styleKeywords, height, weight');

    if (actorError) {
      console.error('Error fetching actors from Supabase:', actorError.message);
      return [];
    }

    console.log('Supabase에서 가져온 배우 목록:', actors);

    // 캐릭터 정보에서 나이와 성별 추출
    const characterAge = extractAgeFromCharacterInfo(characterInfo);
    const characterGender = extractGenderFromCharacterInfo(characterInfo);

    // 배우 나이 계산 및 캐릭터와의 나이 차이 계산
    actors.forEach(actor => {
      actor.age = calculateAge(actor.birthdate);
      actor.ageDifference = Math.abs(actor.age - characterAge);
    });

    // 캐릭터 성별과 일치하는 배우 필터링
    let filteredActors = actors.filter(actor => actor.gender === characterGender);

    // 배우 정보를 JSON 배열로 변환
    const actorList = filteredActors.map(actor => ({
      name: actor.name,
      age: actor.age,
      gender: actor.gender,
      styles: actor.styleKeywords,
      height: actor.height,
      weight: actor.weight
    }));

    // 프롬프트를 명확하고 강력하게 작성
    const systemMessage = `
당신은 시놉시스와 캐릭터 정보를 기반으로 제공된 배우 목록에서 적합한 배우를 추천하는 AI 캐스팅 어시스턴트입니다.

- 제공된 배우 목록에서 **반드시** 배우를 선택해야 하며, 목록에 없는 배우는 언급하지 마세요.
- 정확한 매칭이 없을 경우, **가장 가까운** 배우를 추천하세요.
- 각 추천에 대해 배우의 특성이 캐릭터와 어떻게 부합하는지 설명하세요.

다음은 배우 목록입니다 (JSON 배열):

${JSON.stringify(actorList, null, 2)}

**당신의 답변은 다음 JSON 형식으로 제공하세요:**

[
  {
    "name": "배우 이름",
    "reason": "추천 이유"
  },
  ...
]

반드시 제공된 배우 목록에서만 선택하세요.
`;

    const userMessage = `시놉시스: ${synopsis}\n캐릭터 정보: ${characterInfo}`;

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: systemMessage },
          { role: 'user', content: userMessage },
        ],
        max_tokens: 1000,
        temperature: 0.7,
        n: 1,
        stop: null,
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData.error.message || 'Failed to fetch AI recommendations.');
    }

    const aiRecommendedText = responseData.choices[0].message.content;

    // AI의 응답을 JSON으로 파싱
    let recommendations = [];
    try {
      recommendations = JSON.parse(aiRecommendedText);
    } catch (parseError) {
      console.error('Error parsing AI response:', parseError);
      return [];
    }

    // 추천된 배우가 제공된 배우 목록에 있는지 확인
    const finalActors = recommendations
      .map(rec => {
        const actor = actors.find(dbActor => dbActor.name === rec.name);
        return actor ? { ...actor, reason: rec.reason } : null;
      })
      .filter(Boolean);

    // 최소 3-10명의 배우가 되도록 조정
    while (finalActors.length < 3 && filteredActors.length > 0) {
      const randomActor = filteredActors[Math.floor(Math.random() * filteredActors.length)];
      if (!finalActors.find(actor => actor.id === randomActor.id)) {
        finalActors.push({ ...randomActor, reason: '최소 인원 충족을 위한 임의 선택' });
      }
    }

    return finalActors.slice(0, 10); // 최대 10명까지만 반환

  } catch (error) {
    console.error('Error fetching AI recommendations:', error.message);
    return [];
  }
};

// 생년월일로 나이 계산 함수
const calculateAge = (birthdate) => {
  const birth = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const m = today.getMonth() - birth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age;
};

// 캐릭터 정보에서 나이 추출 함수
const extractAgeFromCharacterInfo = (characterInfo) => {
  const ageMatch = characterInfo.match(/(\d+)\s*세/);
  return ageMatch ? parseInt(ageMatch[1], 10) : 25; // 기본값 25세
};

// 캐릭터 정보에서 성별 추출 함수
const extractGenderFromCharacterInfo = (characterInfo) => {
  if (characterInfo.includes('남자') || characterInfo.includes('남성')) {
    return '남자';
  } else if (characterInfo.includes('여자') || characterInfo.includes('여성')) {
    return '여자';
  }
  return '남자'; // 기본값 남자
};