// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // 'react-dom/client'에서 가져옴
import App from './App';
import './index.css'; // 전역 스타일링 파일

// createRoot를 사용하여 루트 요소를 초기화
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);